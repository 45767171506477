import React from 'react';
import styles from './Back.module.css';
import cn from 'classnames';

import {ReactComponent as Arrow} from '../../assets/arrow-left.svg'
import { useNavigate } from 'react-router-dom';

type BackProps = {
    className?: string;
    onClick?: () => void;
}  
export const Back: React.FC<BackProps> = ({className, onClick}) => {
    const navigate = useNavigate();
    
    return (
        <div className={cn(styles.root, className)} onClick={onClick || (() => {navigate(-1)})}>
            <Arrow/>
            <div className={styles.text}>Back</div>
        </div>
    )
};
