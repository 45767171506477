import React from 'react';
import cn from 'classnames';

import styles from './PackageItem.module.css';
import {ReactComponent as RadioButtonSelected} from '../../assets/radio-selected.svg'
import {ReactComponent as RadioButtonUnselected} from '../../assets/radio-unselected.svg'



export interface PackageItemProps {
    id: string;
    title?: string;
    onClick?: (id: string) => void;
    selected: boolean;
    className?: string;
    color?: 1 | 2 | 3 | 4;
}

export const PackageItem: React.FC<PackageItemProps> = ({ title, id, onClick, selected, color, className}) => {


  return (
    <div 
      className={cn(styles.root, 
        styles[`root-color-${color || 1}`], 
        className)
      } 
      onClick={() => onClick && onClick(id)}
    >
      <div className={styles.blob}>
        {selected ? <RadioButtonSelected className={styles[`radioButton-color-${color || 1}-dark`]}/> 
          : 
          <RadioButtonUnselected className={styles[`radioButton-color-${color || 1}-dark`]}/>
        }
      </div>
      <div className={styles.title}>
          {title}
      </div>
    </div>
  );
};



