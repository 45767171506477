import React, { useState } from 'react';
import styles from './PackagePicker.module.css';
import { useProducts } from '../../hooks';
import { useBalance } from '../../hooks';
import { useSession } from '../../authentication';
import { Button } from '../../components/Button';

import {ReactComponent as Crown} from '../../assets/crown.svg'
import { PackageList } from '../../components/PackageList';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components';

interface PackagePickerProps {}

export const PackagePicker: React.FC<PackagePickerProps> = () => {
  const { session } = useSession();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string | null>(null);
  const products = useProducts();
  const balance = useBalance();

  if (!session) {
    return (<div>Not logged in</div>)
  }

  return (
    <div className={styles.root}>
      <Header fixedPosition={true} withBackButton={true} withBackground={false}/>
      <div className={styles.thumbnailContainer}>
        <Crown />
      </div>
      <div className={styles.content}>
        <div className={styles.credits}>
          {`${balance} Credits Left`}
        </div>
        <div className={styles.paragraph}>
          Every purchased story will entitle you to a magical story experience just for you.
        </div>
      </div>
      <div className={styles.buttons}>
        <PackageList 
          className={styles.list}
          items={products?.map(({id, description}) => ({id, title: description})) || []} 
          onItemClick={setSelected}
          selected={selected || ''}
        />
      </div>
      <div className={styles.payButtonContainer}>
        <Button disabled={!selected} text='Payment' onClick={() => {navigate(`/payment/${selected}`)}}/>
      </div>
    </div>
  );
};
