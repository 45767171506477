import React, { useState } from 'react';
import styles from './Payment.module.css';
import { PaymentButtons } from '../../payment';
import { useProduct } from '../../hooks';
import {ReactComponent as Crown} from '../../assets/crown.svg'
import { useSession } from '../../authentication';
import { useParams } from 'react-router-dom';
import { Back } from '../../components/Back';
import { useModal } from '../../hooks/modals';
import { Header } from '../../components';

interface PaymentProps {}

export const Payment: React.FC<PaymentProps> = () => {
  const { session } = useSession();
  const { showModal } = useModal();
  const { slug: productId } = useParams();
  const product = useProduct(productId);

  const [message, setMessage] = useState<string | undefined>(undefined)

  const handlePaymentComplete = async () => {
    setMessage('Payment Successfull');
    showModal('paymentSuccess');
  }

  const handleFailure = (message: string) => {
    setMessage(message);
    showModal('paymentError');
  }

  if (!session) {
    return (<div>Not logged in</div>)
  }

  if (productId === undefined) {
    return (<div>No Product Defined</div>)
  }

  if (product === undefined) {
    return (<div></div>)
  }

  return (
    <div className={styles.root}>
      <Header fixedPosition={true} withBackButton={true} />
      <div className={styles.mainContainer}> 
        <div className={styles.thumbnailContainer}>
          <Crown />
        </div>
        <div className={styles.content}>
          <div className={styles.package}>
            {product.description}
          </div>
        </div>
      
        {message && <div>{message}</div>}
        
        <div className={styles.buttonSection}>
          <PaymentButtons
            productId={product.id}
            session={session}
            onComplete={handlePaymentComplete} 
            onFailure={handleFailure} 
            className={styles.paymentButtons}
            />
        </div>
      </div>
    </div>
  );
};
