import { useEffect, useState } from "react";
import { supabaseAPI } from "../supabase";

export const useSignOut = () => {
  const [error, setError] = useState<string | null>(null);

  const signOut = async () => {
    try {
      const { error } = await supabaseAPI.getSupabaseClient().auth.signOut();
      if (error) {
        setError(error.message);
      }
    } catch (error) {
      setError("Error Signout");
    }
  };

  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  return signOut;
};
