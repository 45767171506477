import React, {useCallback, useEffect}  from 'react';
import cn from 'classnames';
import _ from 'lodash';

import styles from './FooterAudioPlayer.module.css';
import {ReactComponent as Pause} from '../../assets/audio-pause.svg'
import {ReactComponent as Play} from '../../assets/audio-play.svg'


type FooterAudioPlayerProps = {
    className?: string;
    fixedBottom?:boolean;
    audioUrls: (string | undefined)[];
    currentIndex: number;
}

export const FooterAudioPlayer: React.FC<FooterAudioPlayerProps> = ({className, fixedBottom, audioUrls, currentIndex}) => { 
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [audioElements, setAudioElements] = React.useState<(HTMLAudioElement | undefined)[] | null>(null);

    // set audio elements
    useEffect(() => {
        if (!audioElements) {
            setAudioElements(audioUrls.map(url => url !== undefined  ? new Audio(url) : undefined));
        } else if (!_.isEqual(audioUrls, audioElements?.map(a => a?.src)) ) {  
            setAudioElements(audioUrls.map(url => url !== undefined  ? new Audio(url) : undefined));
        }
    }, [audioUrls, setAudioElements, audioElements]);


    const pauseAll = useCallback(() => {
        audioElements?.forEach(a => {
            if (a && !a.paused) {
                a.pause();
            }
        })
    }, [audioElements])

    const playCurrent = useCallback(() => {
        pauseAll();

        const currentAudio = audioElements && audioElements[currentIndex];
        console.log('currentAudio', currentAudio);
        if (currentAudio) {
            currentAudio.play();
        }
    }, [audioElements, currentIndex, pauseAll])

    const isCurrentAudioElementPlaying = useCallback(() => {
        return audioElements && audioElements[currentIndex] && !audioElements[currentIndex]?.paused;
    }, [audioElements, currentIndex])

    useEffect(() => {
        return () => {
            pauseAll();
        }
    }, [pauseAll])


    useEffect(() => {
        if (isPlaying && !isCurrentAudioElementPlaying())  {   
            playCurrent();
        }
    }, [currentIndex, pauseAll, playCurrent, isPlaying, isCurrentAudioElementPlaying]);

    const handlePauseClick = useCallback(() => {
        pauseAll();
        setIsPlaying(false);
    }, [pauseAll])

    const handlePlayClick = useCallback(() => {
        playCurrent();
        setIsPlaying(true);
    }, [playCurrent]);

    
    return (
        <div className={cn(styles.root,{[styles.fixedBottom]: fixedBottom},className)}>
            { isPlaying ? 
                <Pause onClick={handlePauseClick} /> 
                : 
                <Play onClick={handlePlayClick}/>
            }
        </div>
    )
}