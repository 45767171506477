import React from 'react';
import { ReactComponent as Info} from '../../../assets/info.svg'
import styles from './SectionTitle.module.css';

type SectionTitleProps = {
    title: string;
    tooltipInfo?: string;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({title, tooltipInfo}) => (
    <div className={styles.root}>
        <h1 className={styles.title}>{title}</h1>
        {tooltipInfo && <Info className={styles.icon} />}
    </div>
);