import React from 'react';
import cn from 'classnames';

import styles from './CardAdd.module.css';

import {ReactComponent as Plus} from '../../assets/plus.svg'
import {ReactComponent as CardBackground} from '../../assets/card-background.svg'

export interface CardAddProps {
    id: string;
    selected?: boolean;
    title?: string;
    onClick?: (storyId: string) => void;
    thumbnail?: string;
    className?: string;
    color?: 1 | 2 | 3 | 4;
}

export const CardAdd: React.FC<CardAddProps> = ({selected, thumbnail, title, id, onClick, color, className}) => {

  return (
    <div 
      className={cn(styles.root, 
        styles[`root-color-${color || 1}`], 
        className)
      } 
      onClick={() => onClick && onClick(id)}
    >
      <CardBackground className={cn(styles.background, styles[`root-color-${color || 1}-dark`])}/>
      <Thumbnail className={styles.thumbnail} src={thumbnail}/>
      
      { title && <div className={styles.title}>
          {title}
        </div>
      }
    </div>
  );
};

type ThumbnailProps = {
  src?: string;
  className?: string;
}

const Thumbnail: React.FC<ThumbnailProps> = ({src, className}) => (
  <div className={className}>
    <Plus />
  </div>
)

