import { Session } from "@supabase/supabase-js";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

const getAllProducts = async (session: Session | null) => {
  if (!session) {
    throw new Error("No Session provided");
  }

  try {
    const response = await fetch(`${API_ROUTE}/api/products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session }),
    });

    const orderData = await response.json();
    return orderData;
  } catch (error) {
    console.log("Failed to get products", error);
  }
};

export const productAPI = {
  getAllProducts,
};
