import React from 'react';
import styles from './Button.module.css';
import cn from 'classnames';

interface ButtonProps {
    text?: string;
    onClick: () => void;
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ text, onClick, className, children, disabled }) => (
    <div className={cn(styles.root, {[styles.disabled]: disabled}, className)} onClick={() => !disabled && onClick()}>
        {text || children}
    </div>
);
