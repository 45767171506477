// src/Book.tsx
import React, { useMemo, useState } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useParams } from 'react-router-dom';

import styles from './Story.module.css';
import { useStory } from '../../hooks';
import { StoryDetails } from '../../App/types';
import useImagePreloader from './imagePreloader';
import { Page, PageProps } from './Components/Page';
import { usePageDrag } from './utils/usePageDrag';
import { Header } from '../../components';
import { FooterAudioPlayer } from '../../components/FooterAudioPlayer';

interface StoryProps {
}


export const Story: React.FC<StoryProps> = () => {
  const { slug } = useParams();
  const story = useStory(slug);

  const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, pages.length - 1));
  const goToPrevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));

  const pages = useMemo(() => storyDetailsToPages(story), [story]);
  const urls = useMemo(() => getStoryImages(story), [story]);
  const speechUrls = useMemo(() => getStorySpeechUrls(story), [story]);
  const hasSpeech = useMemo(() => speechUrls.filter(v => !!v).length > 0, [speechUrls]);
  const [currentPage, setCurrentPage] = useState(0);
  const isLastPage = currentPage === pages.length - 1;
  
  // Need to optimize, urls array changes ref every time, which causes the preload image to run every time
  useImagePreloader(urls.filter((v): v is string => !!v));
  
  const [springs, api] = useSprings(pages.length, (i) => ({
    from: {x: 0, y: 0, scale: 1, rot: 0, zIndex: pages.length - i}, 
    zIndex: pages.length - i, x: 0, y: 0, scale: 1, 
    rot: 0 
  }));
  
  const bind = usePageDrag(currentPage, isLastPage, goToNextPage, goToPrevPage, api);

  const trans = (r: number, s: number) => `rotateZ(${r * 20}deg) scale(${s})`;

  return (
    <div className={styles.root}>
      <Header fixedPosition={false} withBackButton={true} withBackground={false}/>
      <div 
        {...bind()}
        className={styles.pagesContainer}
      >
        { pages.map((page, index) => (
          <animated.div
            key={index} 
            style={{
              zIndex: springs[index].zIndex,
              x: springs[index].x,
              y: springs[index].y, 
              transform: interpolate([springs[index].rot, springs[index].scale], trans)}} 
            className={styles.pageContainer}
          >
            <Page
              {...page}
              pageIndex={index}
              pageAmount={pages.length}
              color={index % 2 === 0 ? 2 : 3}
              className={styles.page}
            />
          </animated.div>))
        }
      </div>
      {hasSpeech && <FooterAudioPlayer audioUrls={speechUrls} fixedBottom={false} currentIndex={currentPage} />}
    </div>
  );
};

const getStoryImages = (storyDetails?: StoryDetails | null): (string | undefined)[] => storyDetails?.content?.sections.map(section => section.imageUrl) || [];
const getStorySpeechUrls = (storyDetails?: StoryDetails | null): (string | undefined)[] => storyDetails?.content?.sections.map(section => section.speechUrl) || [];

const storyDetailsToPages = (story?: StoryDetails | null): Omit<PageProps, 'pageAmount' | 'pageIndex'>[] => {
  if (!story?.content) {
    return [];
  }

  const title = story.name;
  return story.content.sections.map((section, index) => ({
    text: section.text, 
    imageUrl: section.imageUrl,
    speechUrl: section.speechUrl,
    ...(index === 0 && {title})
  }));
}

