import { Session } from "@supabase/supabase-js";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

const createOrder = async (productId: string, session: Session) => {
  const response = await fetch(`${API_ROUTE}/api/orders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    // use the "body" param to optionally pass additional order information
    // like product ids and quantities
    body: JSON.stringify({
      productId,
      session,
    }),
  });

  const orderData = await response.json();
  console.log("Create Order Order Data", orderData);

  if (orderData.id) {
    return orderData.id;
  } else {
    const errorDetail = orderData?.details?.[0];
    const errorMessage = errorDetail
      ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
      : JSON.stringify(orderData);

    throw new Error(errorMessage);
  }
};

const approveOrder = async (data: any, actions: any) => {
  const response = await fetch(
    `${API_ROUTE}/api/orders/${data.orderID}/capture`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const orderData = await response.json();
  // Three cases to handle:
  //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
  //   (2) Other non-recoverable errors -> Show a failure message
  //   (3) Successful transaction -> Show confirmation or thank you message

  const errorDetail = orderData?.details?.[0];

  if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
    return actions.restart();
  } else if (errorDetail) {
    // (2) Other non-recoverable errors -> Show a failure message
    throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
  } else {
    // (3) Successful transaction -> Show confirmation or thank you message
    // Or go to another URL:  actions.redirect('thank_you.html');
    const transaction = orderData.purchase_units[0].payments.captures[0];

    console.log(
      "Capture result",
      orderData,
      JSON.stringify(orderData, null, 2)
    );

    return transaction;
  }
};

export const paymentsAPI = {
  paypal: {
    createOrder,
    approveOrder,
  },
};
