import React from 'react';
import cn from 'classnames';

import styles from './ListItem.module.css';

// import {ReactComponent as More} from '../../assets/three-vertical-dots.svg'
import {ReactComponent as Play} from '../../assets/play.svg'
import {ReactComponent as Star} from '../../assets/star.svg'
import {ReactComponent as Moon} from '../../assets/moon.svg'
import {ReactComponent as Cloud} from '../../assets/cloud.svg'


export interface ListItemProps {
    id: string;
    index: number;
    title?: string;
    onClick: (storyId: string) => void;
    thumbnail?: string;
    className?: string;
    color?: 1 | 2 | 3 | 4;
}

export const ListItem: React.FC<ListItemProps> = ({index, thumbnail, title, id, onClick, color, className}) => (
  <div 
    className={cn(styles.root, 
      styles[`root-color-${color || 1}`], 
      className)
    } 
    onClick={() => id && onClick(id)}
  >
    <BackgroundImage index={index} />
    <Thumbnail className={styles.thumbnail} src={thumbnail}/>
    
    <div className={styles.title}>
        {title}
    </div>
    <div className={styles.controls}>
      <Play />
      {/* <div className={cn(styles.blob, styles[`color-${color || 1}-dark`])}>
        <More />
      </div> */}
    </div>
  </div>
);

const BackgroundImage: React.FC<{index?: number}> = ({index = 1}) => (
  <div className={cn(styles.backgroundImageContainer, {[styles.moon]:index % 3 === 1})}>
      {index % 3 === 0 && <Star className={styles.backgroundImage}/>}
      {index % 3 === 1 && <Moon className={cn(styles.backgroundImage, styles.moon)}/>}
      {index % 3 === 2 && <Cloud className={styles.backgroundImage}/>}
  </div>
)

type ThumbnailProps = {
  src?: string;
  className?: string;
}

const Thumbnail: React.FC<ThumbnailProps> = ({src, className}) => (
  <div className={className}>
    {src && <img className={styles.image} alt='thumbnail' src={src}/>} 
  </div>
)


