import React from 'react';
import cn from 'classnames';

import {ReactComponent as Star} from '../../assets/star.svg'
import {ReactComponent as Moon} from '../../assets/moon.svg'
import {ReactComponent as Cloud} from '../../assets/cloud.svg'
import {ReactComponent as Arrow} from '../../assets/arrow-right.svg'

import styles from './MenuItem.module.css';

export interface MenuItemProps {
    id: string;
    title?: string;
    onClick?: () => void;
    className?: string;
    icon?: JSX.Element;
    index?: number;
    color?: 1 | 2 | 3 | 4;
    showArrow?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({ title, id, onClick, color, className, index, icon, showArrow}) => {
  return (
    <div 
      className={cn(styles.root, 
        styles[`root-color-${color || 1}`], 
        className)
      } 
      onClick={() => onClick && onClick()}
    >
      <BackgroundImage index={index || 1} />
      <Thumbnail className={styles.thumbnail} icon={icon}/>
      <div className={styles.title}>
          {title}
      </div>
      {showArrow && <ArrowElement className={styles.arrow}/>}
    </div>
  );
};


const BackgroundImage: React.FC<{index?: number}> = ({index = 1}) => (
  <div className={cn(styles.backgroundImageContainer, {[styles.moon]:index % 3 === 1})}>
      {index % 3 === 0 && <Star className={styles.backgroundImage}/>}
      {index % 3 === 1 && <Moon className={cn(styles.backgroundImage, styles.moon)}/>}
      {index % 3 === 2 && <Cloud className={styles.backgroundImage}/>}
  </div>
)

type ThumbnailProps = {
  className?: string;
  icon?: JSX.Element;
}

const Thumbnail: React.FC<ThumbnailProps> = ({icon, className}) => (
  <div className={className}>
    {icon} 
  </div>
)

const ArrowElement: React.FC<{className?: string}> = ({className}) => (
  <div className={className}>
    <Arrow /> 
  </div>
)



