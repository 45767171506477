import React from 'react';
import cn from 'classnames';

import styles from './List.module.css';
import { ListItem } from '../ListItem/ListItem';
import { useStories } from '../../hooks';
import { StoryDetails } from '../../App/types';
import { ListItemWriting } from '../ListItemWriting';


interface Item {
  id: string;
  title?: string;
  thumbnail?: string;
  created_at: string;
}

interface ListProps {
    onItemClick: (id: string) => void;
    className?: string;
}

const compareDates = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  return dateA.getTime() - dateB.getTime();
};

export const List: React.FC<ListProps> = ({ onItemClick, className }) => {
  const [stories] = useStories();

  const completedStories = stories?.filter(story => story.status === "completed");
  const inProgressStories = stories?.filter(story => story.status !== "completed");

  if (!completedStories && !inProgressStories) {
    return null;
  }

  
  return (
    <div className={cn(styles.root, className)}>
      { inProgressStories &&
        inProgressStories
        .sort((a, b) => compareDates(b.created_at, a.created_at))
        .map(storyToListItem)
        .map((item, index) => (
          <ListItemWriting 
            className={cn({
              [styles.rotateRight]: index % 2 !== 0,
              [styles.rotateLeft]: index % 2 === 0,
              [styles.rotateLeftSmall]: index === 0,
            })}
            {...item}
            key={item.id} 
          />))
      }
      { completedStories &&
        completedStories
        .sort((a, b) => compareDates(b.created_at, a.created_at))
        .map(storyToListItem)
        .map((item, index) => (
          <ListItem 
            index={index}
            className={cn({
              [styles.rotateRight]: index % 2 !== 0,
              [styles.rotateLeft]: index % 2 === 0,
              [styles.rotateLeftSmall]: index === 0,
            })}
            {...item}
            color={((index % 4) + 1) as 1 | 2 | 3 | 4}
            key={item.id} 
            onClick={onItemClick} 
          />))
    }
    </div>
  );
}

const storyToListItem = (story: StoryDetails): Item =>  (
  {
    id: story.id,
    title: story.name,
    thumbnail: story.thumbnail,
    created_at: story.created_at,
  })