import React, { useState } from 'react';
import styles from './Create.module.css';
import { TextArea } from '../../components/TextArea';
import { CardCarousel } from '../../components/CardCarousel';
import { Button } from '../../components/Button';
import { useSession } from '../../authentication';
import { useHasBalanceRemaining, useSeeds } from '../../hooks';
import { storiesAPI } from '../../apis';
import { Back } from '../../components/Back';
import { queryClient } from '../..';
import { useGlobalModalContext } from '../../components/GlobalModal/GlobalModal';
import { useNavigate } from 'react-router-dom';
import { Seed } from '../../App/types';
import { SectionTitle } from './SectionTitle';

interface CreateProps {}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const createPrompt = (inputTextAbout: string, inputTextCharacters: string,  selectedSeeds: Seed[]) => {
  const activities = selectedSeeds.filter(seed => seed.type === 'activity').map(seed => seed.prompt);
  const characters = selectedSeeds.filter(seed => seed.type === 'character').map(seed => seed.prompt);

  const hasAboutText = inputTextAbout.length > 0;
  const hasCharacterText = inputTextCharacters.length > 0;
  const hasActivities = activities.length > 0;
  const hasCharacters = characters.length > 0;

  const aboutPrompt = hasAboutText ? `The story should be about ${inputTextAbout}` : '';
  const activitySeedsPrompt = hasActivities ? `The story should also include the following activities: ${activities.join(', ')}` : '';

  const charactersPrompt = hasCharacterText ? `The story should include the following characters: ${inputTextCharacters}` : '';
  const characterSeedsPrompt = hasCharacters ? `The story should also include the following characters: ${characters.join(', ')}` : '';
  
  return aboutPrompt + activitySeedsPrompt + charactersPrompt + characterSeedsPrompt;
}

export const Create: React.FC<CreateProps> = () => {
  const { session } = useSession();
  const navigate = useNavigate();
  const [inputTextAbout, setInputTextAbout] = useState('');
  const [inputTextCharacters, setInputTextCharacters] = useState('');
  const [selectedSeeds, setSelectedSeeds] = useState<string[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [seeds] = useSeeds();
  const {showModal} = useGlobalModalContext();

  const [loadingBalance, hasBalanceRemaining] = useHasBalanceRemaining();

  const seedCardItems = seeds?.map(seed => ({id: seed.id, title: seed.title, thumbnail: seed.url, type: seed.type})) ?? [];

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>, type: 'about' | 'characters') => {
    if (type === 'about') setInputTextAbout(event.target.value);
    else setInputTextCharacters(event.target.value);
  };


  const getSelectedSeeds = () => {  
    return seeds?.filter(item => selectedSeeds.includes(item.id));
  }

  const handleBuyCreditClick = () => {
    navigate('/package');
  }

  const handleCreateClick = async () => {
    setDisabled(true);
    if (hasBalanceRemaining) {
      const prompt = createPrompt(inputTextAbout, inputTextCharacters, getSelectedSeeds() || []);

      storiesAPI.create(prompt, session);
      await queryClient.invalidateQueries('storiesData');
      delay(300);
      showModal('storySuccess');
    }
    setInputTextAbout('');
    setInputTextCharacters('');
  };
  const handleCarouselClick = (item:  {type: 'item', id: string} | {type: 'add'}, type: 'seed' | 'style' | 'character') => {
    if (item.type === 'add') { 
      console.log('add new character');
      // HERE direct to add cahracter page
      return
    } 
    if (type === 'seed') {
      if (selectedSeeds.includes(item.id)) {
        setSelectedSeeds(selectedSeeds.filter(seedId => seedId !== item.id));
      } else {
        setSelectedSeeds([...selectedSeeds, item.id]);
      }
    } 
  }

  const hasContent = inputTextAbout.length > 0 || inputTextCharacters.length > 0 || selectedSeeds.length > 0;

  return (
    <div className={styles.root}>
       <div className={styles.header}>
        <Back className={styles.backButton}/>
      </div>
      <div className={styles.sectionsContainer}>
        <h1 className={styles.mainTitle}>Create Your Story</h1>
      </div>
      <div className={styles.sectionsContainer}>
        <div className={styles.section}>
          <SectionTitle title={'Your Adventure'}/>
          <h2 className={styles.subtitle}>What magical world will your story take us to? The more you provide, the richer your story will be! </h2>
          <div className={styles.textAreaContainer}>
            <TextArea 
              value={inputTextAbout} 
              className={styles.textArea}
              onChange={(event) => handleInputChange(event, 'about')} 
              placeholder={"Describe it here!"}
              />
          </div>
        </div>
        
        {seedCardItems && <div className={styles.section}>
          <SectionTitle title={'Tale Ingredients'}/>
          <h2 className={styles.subtitle}>Choose some magical ingredients to sprinkle throughout your tale!</h2>
          <CardCarousel 
            selected={selectedSeeds} 
            onClick={(item) => handleCarouselClick(item, 'seed')} 
            className={styles.carousel} 
            items={seedCardItems.filter(item => item.type === 'activity')}
            />
        </div>}
        <div className={styles.section}>
          <SectionTitle title={'Introduce Your Heroes'}/>
          <h2 className={styles.subtitle}>Who will be the stars of your epic adventure? Share their names, age, hair color, eye color, and any other details that make them unique! </h2>
          <div className={styles.textAreaContainer}>
            <TextArea 
              value={inputTextCharacters} 
              className={styles.textArea}
              onChange={(event) => handleInputChange(event, 'characters')} 
              placeholder={"Describe it here!"}
              />
          </div>
        </div>
        {seedCardItems && <div className={styles.section}>
          <SectionTitle title={'Your Sidekicks'}/>
          <h2 className={styles.subtitle}>Time to recruit some trusty companions! Pick characters to join the fun!</h2>
          <CardCarousel 
            selected={selectedSeeds} 
            onClick={(item) => handleCarouselClick(item, 'seed')} 
            className={styles.carousel} 
            items={seedCardItems.filter(item => item.type === 'character')}
            />
        </div>}
      </div>
      <div className={styles.buttonContainer}>
        { loadingBalance || hasBalanceRemaining ? 
          <Button 
            className={styles.button} 
            disabled={disabled || !hasContent} 
            text="Create Story" 
            onClick={handleCreateClick} 
          /> :
          <Button 
            className={styles.button} 
            text="Buy Credit" 
            onClick={handleBuyCreditClick} 
          />
        }
      </div>
    </div>
  );
};

