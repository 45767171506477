import { Session } from "@supabase/supabase-js";
import { Seed } from "../../App/types";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

const getAllSeeds = async (session: Session | null): Promise<Seed[] | undefined> => {
  if (!session) {
    throw new Error("No Session provided");
  }

  try {
    const response = await fetch(`${API_ROUTE}/api/seeds`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session }),
    });

    const seedData = await response.json();
    return seedData;
  } catch (error) {
    console.log("Failed to get seeds", error);
  }
};

const getAllSeedsByType = async (session: Session | null, type: Seed['type']): Promise<Seed[] | undefined> => {
  if (!session) {
    throw new Error("No Session provided");
  }

  try {
    const response = await fetch(`${API_ROUTE}/api/seeds`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session, type }),
    });

    const seedData = await response.json();
    return seedData;
  } catch (error) {
    console.log("Failed to get seeds", error);
  }
};


export const seedAPI = {
  getAll: getAllSeeds,
  getAllByType: getAllSeedsByType,
};
