import styles from './Login.module.css';
// import {ReactComponent as Logo} from '../../assets/logo.svg'
import { Authentication } from "../../authentication";
import logo from '../../assets/logo.png';



export const Login = () => (
    <div className={styles.root}>
        <div className={styles.logoContainer}>
            <img src={logo} alt="random"></img>
            {/* <Logo /> */}
        </div>
       <Authentication />
    </div>
)
