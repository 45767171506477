import React from 'react';
import cn from 'classnames';
import {ReactComponent as More} from '../../assets/three-vertical-dots.svg'
import styles from './Header.module.css';
import { useNavigate } from 'react-router-dom';
import { Back } from '../Back';

interface HeaderProps {
    className?: string;
    withBackButton?: boolean;
    withTitle?: boolean;
    withMenu?: boolean;
    withBackground?: boolean;
    fixedPosition?: boolean;
}

export const Header: React.FC<HeaderProps> = ({className, withBackButton, withMenu, withTitle, fixedPosition, withBackground}) => { 
    const navigate = useNavigate();
    return (
        <div className={cn(styles.root, {
                [styles.fixed]: fixedPosition, 
                [styles.withBackground]: withBackground
            }, className)}
        >
            {withBackButton && <Back />}
            {withTitle && <div className={styles.title}>My Stories</div>}
            {withMenu && <HamburgerMenu onClick={() => {navigate('/profile')}}  />}
        </div>
    )
}

const HamburgerMenu: React.FC<{src?: string, onClick?: () => void}> = ({src, onClick}) => {
    return (
        <div className={styles.blob} onClick={onClick}>
            {src ? <img src-={src} alt="profile"/> : <More />}
        </div>)
}