import { Session } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { useSession } from "../authentication";
import { seedAPI } from "../apis/seedAPI";
import { Seed } from "../App/types";

const REFETCH_INTERVAL = 60 * 60 * 1000;

const fetchSeeds = async (session: Session): Promise<Seed[] | undefined> =>
  seedAPI.getAll(session);

export const useSeeds = () => {
  const { session, loading } = useSession();
  const { data, isLoading, error, refetch } = useQuery(
    "seedsData",
    () => fetchSeeds(session!),
    { refetchInterval: REFETCH_INTERVAL, enabled: Boolean(session && !loading) }
  );

  return [data, isLoading, error, refetch] as const;
};
