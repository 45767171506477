import { Session } from "@supabase/supabase-js";
import { Story, isStory, isStoryBase } from "./serverTypes";
import { StoryDetails } from "../../App/types";

const API_ROUTE = process.env.REACT_APP_API_ROUTE;

const getAllStories = async (
  session: Session | null
): Promise<StoryDetails[]> => {
  if (!session) {
    console.log("No session");
    return [];
    // throw new Error("No Session provided");
  }

  const stories = await fetch(`${API_ROUTE}/api/stories`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ session }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error fetching data:", error));

  if (!stories.every(isStoryBase)) {
    throw Error("getAllStories: not of correct type");
  }

  return stories.map(storyToStoryDetails);
};

const storyToStoryDetails = (story: Story): StoryDetails => ({
  id: story.id,
  name: story.name,
  thumbnail: story.thumbnail,
  status: story.status,
  created_at: story.created_at,
  content: {
    sections: story.sections || [],
    characters: story.characters || [],
  },
});

const getStory = async (
  session: Session,
  storyId: string
): Promise<StoryDetails> => {
  if (!session || !storyId) {
    throw new Error("getStory: No Session or storyId provided");
  }

  const story = await fetch(`${API_ROUTE}/api/story`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ session, storyId }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error fetching data:", error));

  if (!isStory(story)) {
    throw Error("getStory: not of correct type");
  }

  return storyToStoryDetails(story);
};

const createStory = async (
  content: string,
  session: Session | null
): Promise<void> => {
  console.log("Create Story StoriesAPI", content);

  if (!session) {
    console.log("No session");
    // throw new Error("No Session provided");
  }

  await fetch(`${API_ROUTE}/api/createStory`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ content: content, session }),
  })
    .then((response) => response.json())
    .then((response) => console.log(response))
    .catch((error) => console.error("Error creating story:", error));
};

export const storiesAPI = {
  create: createStory,
  get: getStory,
  getAll: getAllStories,
};
