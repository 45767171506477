import React from 'react';
import cn from 'classnames';
import { Button } from '../Button';
import styles from './Modal.module.css';

interface ModalProps {
    title?: string;
    text?: string;
    onClick?: () => void;
    buttonTitle?: string;
    thumbnail?: React.ReactNode;
    backdrop?: boolean;
    open?: boolean;
    className?: string;
}

export const Modal: React.FC<ModalProps> = ({thumbnail, title, text, onClick, buttonTitle, open, className}) => (
    <div className={cn(styles.root, className, {[styles.background]: open})}>
        <div className={cn(styles.modalContainer, {[styles.open]: open, [styles.closed]: !open})}>
            {thumbnail && <div className={styles.thumbnail}>{thumbnail}</div>}
            <div className={styles.content}>    
                <div className={styles.title}>{title}</div>
                <div className={styles.text}>{text}</div>
            </div>
            {onClick && buttonTitle && <Button onClick={onClick}>{buttonTitle}</Button>}
        </div>
    </div>
);

