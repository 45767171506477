import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { paymentsAPI } from "../../paymentsAPI";
import { Session } from "@supabase/supabase-js";

interface PaymentButtonsProps {
    onComplete(message?: string): void;
    onFailure(message: string): void;
    session: Session;
    productId: string;
    disabled?: boolean;
    className?: string;
}

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

export const PaymentButtons: React.FC<PaymentButtonsProps> = ({className, onComplete, onFailure, productId, session, disabled = false}) => {
  if (!PAYPAL_CLIENT_ID) {
    throw new Error("PayPal Client ID is not set");
  }

  
  const initialOptions = {
    clientId: PAYPAL_CLIENT_ID,
    enableFunding: "paylater,venmo,card",
    disableFunding: "",
    dataSdkIntegrationSource: "integrationbuilder_sc",
  };

  return (
    <div className={className}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          onError={(error) => {console.error('PayPal Error', error)}}
          disabled={disabled}
          forceReRender={[productId]}
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={async () => {
            try {
              console.log('createOrder', productId)
              return await paymentsAPI.paypal.createOrder(productId, session);
            } catch (error) {
              console.error(error);
              onFailure(`Could not initiate PayPal Checkout...${error}`);
            }
          }}
          onApprove={async (data, actions) => {
            try {
              console.log('On Approve', data, actions);
              await paymentsAPI.paypal.approveOrder(data, actions);
              onComplete()
            } catch (error) {
              console.error(error);
              onFailure(
                `Sorry, your transaction could not be processed...${error}`,
              );
            }
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
}

