import { Session } from "@supabase/supabase-js";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { StoryDetails } from "../App/types";
import { useSession } from "../authentication";
import { storiesAPI } from "../apis";

const REFETCH_INTERVAL_IN_PROGRESS = 2000;
const REFETCH_INTERVAL_COMPLETED = 60000;

const fetchStories = async (session: Session | null) => {
  if (!session) {
    return [];
  }

  return storiesAPI
    .getAll(session)
    .then((storyDetails) =>
      storyDetails.filter((story) => story.status !== "failed")
    );
};

export const useStories = () => {
  const { session, loading } = useSession();
  const [refetchInterval, setRefetchInterval] = useState(
    REFETCH_INTERVAL_COMPLETED
  );

  const { data, isLoading, error, refetch } = useQuery(
    "storiesData",
    () => fetchStories(session),
    { refetchInterval: refetchInterval, enabled: Boolean(session && !loading) }
  );

  const hasInProgressStories = data?.some(
    (story) => story.status !== "completed"
  );

  if (
    hasInProgressStories &&
    refetchInterval !== REFETCH_INTERVAL_IN_PROGRESS
  ) {
    setRefetchInterval(REFETCH_INTERVAL_IN_PROGRESS);
  } else if (
    !hasInProgressStories &&
    refetchInterval === REFETCH_INTERVAL_IN_PROGRESS
  ) {
    setRefetchInterval(REFETCH_INTERVAL_COMPLETED);
  }

  return [data, isLoading, error, refetch] as const;
};

export const useStory = (storyId?: string) => {
  const { session, loading } = useSession();
  const [story, setStory] = useState<StoryDetails | null>(null);

  useEffect(() => {
    if (session && !loading && storyId) {
      storiesAPI
        .get(session, storyId)
        .then((storyDetails) => {
          setStory(storyDetails);
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [session, storyId, loading]);

  return story;
};
