
import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import styles from './Authentication.module.css';
import { supabaseAPI } from "../../supabase";

export const Authentication = () => (
    <div className={styles.root}>
        <Auth 
            supabaseClient={supabaseAPI.getSupabaseClient()} 
            appearance={{ 
                theme: ThemeSupa, 
                className: {
                    anchor: styles.anchor,
                    message: styles.message,
                    container: styles.container,
                    label: styles.label,
                    button: styles.button,
                    input: styles.input,
                    loader: styles.loader,
                    divider: styles.divider,
                }
            }}
            socialLayout="horizontal"
            providers={[]} 
            redirectTo="app.storyaday.ai/"
            theme=""
        />
    </div>
)
