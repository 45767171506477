import { createClient } from "@supabase/supabase-js";

const SUPABASE_URL = "https://ggltcdshbowmygqgwzck.supabase.co";
const SUPABASE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdnbHRjZHNoYm93bXlncWd3emNrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM3NTgwMjYsImV4cCI6MjAxOTMzNDAyNn0.Idap9l0iFtdgBp3ZWNY6Pd4TNTckQIYWcONkT-3G_XE";

const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY);

export const supabaseAPI = {
  getSupabaseClient: () => supabaseClient,
};
