import { Session } from "@supabase/supabase-js";
import React, { createContext, useContext } from "react";
import { useSession as _useSession } from "../hooks";

type Loading = boolean;
const SessionContext = createContext<{session: (null | Session), loading: Loading}>({session: null, loading: true});

export const SessionProvider: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const {session, loading} = _useSession();
  return (
    <SessionContext.Provider value={{session, loading}}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
