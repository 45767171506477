import React from 'react';

import styles from './ProgressBar.module.css';

interface ProgressBarProps {
    progress: number; // A number between 0 and 100
    ready: boolean;
    completionTime: number; // Time in seconds for the progress to reach 100% from 0%
}

export const ProgressBar: React.FC<ProgressBarProps> = ({progress, ready, completionTime}) => {
    const timeLeft = completionTime - ((progress === 0 ? 1 : progress)/100 * completionTime);
    return (
        <div className={styles.root}>
            <div className={styles.outer}>
                <div className={styles.inner}>
                    <div 
                        className={styles.progress} 
                        style={{ 
                            ...(!ready && {transform: `translateX(${progress-100}%)`}), 
                            transition: `transform ${ready ? `${timeLeft}s` : '0.5s'} ease-out` 
                        }}>
                    </div>
                </div>
            </div>
      </div>
    );
};