import React, { useState, createContext, useContext, useCallback } from 'react';
import styles from './GlobalModal.module.css';

import { useNavigate } from 'react-router-dom';
import { modalConfiguration } from './modalsConfiguration';
import { GlobalModalContextType, GlobalModalProps, ModalType } from './types';
import { ModalProvider } from './ModalProvider';

const initalState: GlobalModalContextType = {
 showModal: () => {},
 hideModal: () => {},
 type: null,
};

const GlobalModalContext = createContext(initalState);

export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<GlobalModalProps> = ({ children }) => {
 const navigate = useNavigate();
 const [modalType, setModalType] = useState<ModalType | null>(null);
 const [isOpen, setIsOpen] = React.useState(false);
    
 const showModal = useCallback((modalType: ModalType, modalProps: any = {}) => {
    setModalType(modalType);
    setTimeout(() => {
      setIsOpen(true);
    }, 300);
    
 }, [setModalType]);

 const hideModal = useCallback(() => {
    isOpen && setIsOpen(false);
    setModalType(null);
 }, [setModalType, isOpen]);

 const onDone = useCallback(() => {
   const navigateTo =  modalType && modalConfiguration[modalType]?.navigateTo;
   setIsOpen(false);
   setTimeout(() => {
      hideModal();
      navigateTo && navigate(navigateTo);
   }, 400)
 }, [modalType, hideModal, navigate])
 
 return (
   <GlobalModalContext.Provider value={{ type: modalType, showModal, hideModal }}>
      { modalType && 
         <ModalProvider 
            className={styles.modal}
            modalType={modalType} 
            isOpen={isOpen} 
            onDone={onDone}
         />
      }
      {children}
   </GlobalModalContext.Provider>
 );
};

