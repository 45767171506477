import { useNavigate } from 'react-router-dom';
import { List } from "../../components/List";
import styles from './Stories.module.css';
import { FooterMenu } from '../../components/FooterMenu';
import { Header } from '../../components';
import { Button } from '../../components/Button';
import { useStories } from '../../hooks';
import { useCallback } from 'react';

interface StoriesProps {}
  
export const Stories: React.FC<StoriesProps> = () => {
    const navigate = useNavigate();
    const [stories] = useStories();
    const noStories = useCallback(()=> stories?.length === 0, [stories]);

    if(!stories) return null;

    return (
        <div className={styles.root}>
            { noStories() ? 
                <EmptyState />: 
                <List className={styles.list} onItemClick={(storyId) => navigate(`story/${storyId}`)}/>
            }
            <Header withMenu={true} withTitle={true} fixedPosition={true} withBackground={true} />
            <FooterMenu className={styles.footer} fixedBottom={true} />
        </div>
    );
};

const EmptyState: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.emptyStateRoot}>
            <div className={styles.title}>Welcome to Story A Day</div>
            <div className={styles.text}>No stories yet, but fear not! With AI magic, you'll craft enchanting tales. Click 'Create' to begin your storytelling journey. Let's weave some wonders! 📚✨📚✨</div>
            <Button onClick={() => navigate('create')} className={styles.createButton}>Create Story</Button>
        </div>
    );
}
