import React, { ChangeEvent, TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import styles from './TextArea.module.css';

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    className?: string;
};

export const TextArea: React.FC<TextAreaProps> = ({className, children, value, onChange, ...rest}) => {
    const [textareaHeight, setTextareaHeight] = useState<string>('auto');
    const textArea = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        updateTextareaHeight(textArea.current!)
    }, [])

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange && onChange(event);
        updateTextareaHeight(event.target);
    };

    const updateTextareaHeight = (element: HTMLTextAreaElement): void => {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
        setTextareaHeight(element.style.height);
    };

    return (
        <div className={cn(styles.root, className)} >
            <textarea 
                className={styles.textArea} 
                {...rest}
                ref={textArea}
                onChange={handleChange}
                style={{ height: textareaHeight }}
                value={value}
            />
        </div>
)};

