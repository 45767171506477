import React from 'react';
import cn from 'classnames';

import styles from './Card.module.css';

import {ReactComponent as RadioButtonSelected} from '../../assets/radio-selected.svg'
import {ReactComponent as RadioButtonUnselected} from '../../assets/radio-unselected.svg'

export interface CardProps {
    id: string;
    selected?: boolean;
    title?: string;
    onClick?: (storyId: string) => void;
    thumbnail?: string;
    className?: string;
    color?: 1 | 2 | 3 | 4;
}

export const Card: React.FC<CardProps> = ({selected, thumbnail, title, id, onClick, color, className}) => {

  return (
    <div 
      className={cn(styles.root, 
        styles[`root-color-${color || 1}`], 
        className)
      } 
      onClick={() => onClick && onClick(id)}
    >
      <Thumbnail className={styles.thumbnail} src={thumbnail}/>
      
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {title}
        </div>
      </div>
      <div className={styles.blob}>
        {selected ? <RadioButtonSelected className={styles[`radioButton-color-${color || 1}-dark`]}/> 
          : 
          <RadioButtonUnselected className={styles[`radioButton-color-${color || 1}-dark`]}/>
        }
      </div>
    </div>
  );
};

type ThumbnailProps = {
  src?: string;
  className?: string;
}

const Thumbnail: React.FC<ThumbnailProps> = ({src, className}) => (
  <div className={className}>
    {src && <img className={styles.image} alt='thumbnail' src={src}/>} 
  </div>
)

