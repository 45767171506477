import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Story } from '../Pages/Story';
import styles from './App.module.css';
import { Login } from '../Pages/Login';
import { Payment } from '../Pages/Payment';
import { Create } from '../Pages/Create';
import { Stories } from '../Pages/Stories';
import { PackagePicker } from '../Pages/PackagePicker';
import { useSession } from '../authentication';
import { Profile } from '../Pages/Profile';

import { GlobalModal } from '../components/GlobalModal/GlobalModal';
import { useSeeds } from '../hooks';

export const App: React.FC = () => {
  const {session } = useSession()
  useSeeds(); //used to prefetch data

  if (!session) {
    return (
    <div className={styles.root}>
      <Login />
    </div>);
  }


  return (
    <div className={styles.root}>
      <GlobalModal>
        <Routes>
          <Route path="/" element={<Stories />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/package" element={<PackagePicker />} />
          <Route path="/payment/:slug" element={<Payment />} />
          <Route path="/story/:slug" element={<Story/>} />
          <Route path="/create" element={<Create /> } />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Stories />} />
        </Routes>
      </GlobalModal>
    </div>
  );
};



