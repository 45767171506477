import React, { useMemo } from 'react';
import styles from './Profile.module.css';
// import profileImage from '../../assets/profile-image.webp';
// import { ProfileThumbnail } from '../../components/ProfileThumbnail';
import { MenuList } from '../../components/MenuList';
import logo from '../../assets/logo.png';

// import {ReactComponent as Edit} from '../../assets/edit.svg'
import {ReactComponent as Crown} from '../../assets/package.svg'
// import {ReactComponent as Chat} from '../../assets/chat.svg'
import {ReactComponent as Logout} from '../../assets/logout.svg'
import { useSignOut } from '../../authentication/hooks';
import { Back } from '../../components/Back';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../authentication';

type ProfileProps = {
}


export const Profile: React.FC<ProfileProps> = () => {
    const logout = useSignOut();
    const { session } = useSession();
    console.log(session)
    const navigate = useNavigate();
    const profileItems = useMemo(() => [
    //     {
    //     id: 'edit-profile',
    //     title: 'Edit Profile',
    //     icon: <Edit />,
    //     showArrow: true,
    // }, 
    {
        id: 'package',
        title: 'Package',
        icon: <Crown />,
        showArrow: true,
        onClick: () => navigate('/package'),
    }, 
    // {
    //     id: 'constuct-us',
    //     title: 'Contact Us',
    //     icon: <Chat />,
    //     showArrow: true,
    // }, 
    {
        id: 'logout',
        title: 'Logout',
        icon: <Logout />,
        onClick: logout,
    }],[logout, navigate])



    return (
        <div className={styles.root}>
            <Back className={styles.back} />
            <div className={styles.picture}>
                <img className={styles.logo} src={logo} alt="random"></img>
                {/* <ProfileThumbnail imageUrl={profileImage} /> */}
            </div>
            <div className={styles.content}>
                {/* <div className={styles.name}>
                    Tomer Marx
                </div> */}
                {   session && 
                    <div className={styles.email}>
                        {session.user.email}
                    </div>
                }
            </div>
            
            <div className={styles.buttonsContainer}>
                <MenuList items={profileItems} />

            </div>
        </div>
    );
};