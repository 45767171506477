import React from 'react';
import cn from 'classnames';
import styles from './Slider.module.css';


type SliderProps = {
    amount: number;
    current: number;
    color?: 1 | 2 | 3 | 4; 
}
export const Slider: React.FC<SliderProps> = ({ amount, current, color }) => {
  let xOffset = 0;
  return (
    <svg className={cn(styles.root, styles[`color-${color || 2}`],)} width="74" height="8" viewBox="0 0 74 8" xmlns="http://www.w3.org/2000/svg">
      {Array.from({ length: amount }).map((_, index) => {
        const isCurrent = index === current;
        const rect = (
          <rect
            key={index}
            width={isCurrent ? '26' : '8'}
            height="8"
            rx="4"
            fill="#C6873F"
            opacity={isCurrent ? '1' : '0.3'}
            x={xOffset}
          />
        );
        xOffset += (isCurrent ? 26 : 8) + 4;
        return rect;
      })}
    </svg>
  );
}