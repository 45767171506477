import { Session } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { supabaseAPI } from "../supabase";
import { useLocalStorage } from "../../hooks/localStorage";

export const useSession = () => {
  const [loading, setLoading] = useState(true);
  const [sessionFetched, setSessionFetched] = useState(false);
  const [session, setSession] = useLocalStorage<Session | null>(
    "session",
    null
  );

  useEffect(() => {
    const fetchSession = async () => {
      const _session = await getSession();
      setSession(_session);
      setSessionFetched(true);
      setLoading(false);
    };

    if (sessionFetched) {
      if (!isSessionValid(session)) {
        setSession(null);
      }
    } else {
      fetchSession();
    }
  }, [setLoading, session, setSession, sessionFetched]);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabaseAPI
      .getSupabaseClient()
      .auth.onAuthStateChange((_event, _session) => {
        setSession(_session);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [setSession]);

  return { session, loading };
};

const getSession = async () => {
  const {
    data: { session },
  } = await supabaseAPI.getSupabaseClient().auth.getSession();
  return session;
};

const isSessionValid = (session: Session | null) => {
  if (!session) {
    return false;
  }
  const now = Math.floor(Date.now() / 1000);
  return session.expires_at && session.expires_at > now;
};
