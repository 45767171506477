import { ReactComponent as Successthumbnail } from "../../assets/success-thumbnail.svg";
import { ModalConfiguration } from "./types";


export const modalConfiguration: ModalConfiguration = {
  storySuccess: {
    title: "Story Magic in Progress!",
    text: "🚀✨ Hang tight! Your magical story is almost ready! 🧙‍♂️🦄",
    buttonTitle: "Home Screen",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "/",
  },
  storyError: {
    title: "Magic Glitch!",
    text: "🚀✨  Uh-oh, our magic wand took a nap! 💤🔮 Try again or give us a silly dance for luck! 🤪🎉",
    buttonTitle: "Try Again",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "/create",
  },
  paymentSuccess: {
    title: "Payment Successfull!",
    text: "🎉✨ Credits have been added to your account. Get ready for magical adventures! 🧙‍♂️📚",
    buttonTitle: "Let's Go!",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "/create",
  },
  paymentError: {
    title: "Oops!",
    text: "🙈 It seems our magic trick misfired! Your payment didn't go through this time, but no worries, let's try that again!",
    buttonTitle: "Try Again",
    thumbnail: <Successthumbnail />,
    onClick: () => {},
    navigateTo: "/package",
  },
};
