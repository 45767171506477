import React from 'react';
import cn from 'classnames';
import styles from './CardCarousel.module.css';
import { Card } from '../Card/Card';
import { CardAdd } from '../CardAdd';

type CardItem = {
    id: string;
    title: string;
    thumbnail: string;
}


interface CardCarouselProps {
    items: CardItem[],
    selected?: string[],
    onClick?: (item: {type: 'item', id: string} | {type: 'add'}) => void
    className?: string;
    addItem?: boolean;
}

export const CardCarousel: React.FC<CardCarouselProps> = ({className, items, selected, onClick, addItem}) => {
    const [shuffledItems, setShuffledItems] = React.useState<CardItem[]>([]);

    React.useEffect(() => {
        // Shuffle the items array if shuffledItems is empty
        if (shuffledItems.length === 0) {
            const shuffled = [...items].sort(() => Math.random() - 0.5);
            setShuffledItems(shuffled);
            return;
        }
        
        // Remove items that no longer exist
        const existingItemIds = items.map(item => item.id);
        const filteredItems = shuffledItems.filter(item => existingItemIds.includes(item.id));
        if (filteredItems.length !== shuffledItems.length) {
            setShuffledItems(filteredItems);
        }
    
        // Add new items
        const newItems = items.filter(item => !shuffledItems.some(prevItem => prevItem.id === item.id));
        if (newItems.length !== 0) {
            setShuffledItems(prevItems => [...prevItems, ...newItems]);
        }
    }, [items, shuffledItems]);

    return (
        <div className={cn(styles.root, className)}>
            {addItem && <CardAdd 
                id="add"
                onClick={() => onClick && onClick({type: 'add'})}
                className={styles.add} />}
            { shuffledItems.map((item, index) => (
                <Card  
                    key={item.id}
                    id={item.id} 
                    color={((index % 4) + 1) as 1 | 2 | 3 | 4}
                    title={item.title} 
                    thumbnail={item.thumbnail} 
                    selected={selected && selected.includes(item.id)} 
                    onClick={() => onClick && onClick({type: 'item', id: item.id})} 
                    className={cn({
                        [styles.rotateRight]: index % 2 === 0,
                        [styles.rotateLeft]: index % 2 !== 0,
                    })}
                />))}
        </div>
    );
};

